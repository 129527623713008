import { IGebouwElementHoeveelheid } from './gebouwenSlice'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import { DetailsList, Link, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import EditPanelGebouwHoeveelheid, { EditMode } from './editPanelGebouwHoeveelheid'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMeetkundigeEenheden, selectAll as selectEenheden } from 'features/beheer/basis/meetkundigeEenheden/meetkundigeEenheidSlice'
import { transformDateString } from 'features/beheer/lemdo/common/support'

interface IHoeveelhedenProps {
  hoeveelheden?: IGebouwElementHoeveelheid[]
  editMode: EditMode
}

interface IConditieProps {
  conditie?: number
}

interface IEenheidProps {
  eenheidId?: number
}

const Eenheid: React.FC<IEenheidProps> = props => {
  const eenheden = useSelector(selectEenheden)
  const dispatch = useDispatch()

  useEffect(() => {
    if (eenheden.length === 0) dispatch(fetchMeetkundigeEenheden())
  }, [])

  const eenheid = eenheden.find(e => e.id === props.eenheidId)
  return eenheid ? <label>{eenheid.code}</label> : <></>
}

const Conditie: React.FC<IConditieProps> = props => {
  let conditie = ''
  switch (props.conditie) {
    case 1:
      conditie = 'Uitstekend'
      break
    case 2:
      conditie = 'Goed'
      break
    case 3:
      conditie = 'Voldoende'
      break
    case 4:
      conditie = 'Matig'
      break
    case 5:
      conditie = 'Onvoldoende'
      break
    case 6:
      conditie = 'Slecht'
      break
    default:
      return <></>
  }
  const text = `${props.conditie} - ${conditie}`
  return <label>{text}</label>
}

const HoeveelhedenList: React.FC<IHoeveelhedenProps> = props => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItemId, setSelectedItemId] = useState<number>()

  const showItem = (id: number) => {
    setSelectedItemId(id)
    openPanel()
  }

  const columns = [
    createColumn(
      'Hoofdelementgroep',
      getPropertyName<IGebouwElementHoeveelheid>('hoofdElementGroep'),
      130,
      undefined,
      (item: IGebouwElementHoeveelheid) => <Link onClick={e => showItem(item.id)}>{item.hoofdElementGroep} - {item.hoofdElementGroepOmschrijving}</Link>
    ),
    createColumn('Plaatsaanduiding', getPropertyName<IGebouwElementHoeveelheid>('plaatsaanduiding'), 115),
    createColumn('Element', getPropertyName<IGebouwElementHoeveelheid>('element'), 55),
    createColumn('Element-omschrijving', getPropertyName<IGebouwElementHoeveelheid>('elementOmschrijving'), 'XXL'),
    createColumn('Hoeveelheid', getPropertyName<IGebouwElementHoeveelheid>('hoeveelheid'), 'M'),
    createColumn('Eenheid', getPropertyName<IGebouwElementHoeveelheid>('eenheidId'), 55, undefined, (item: IGebouwElementHoeveelheid) => (
      <Eenheid eenheidId={item.eenheidId} />
    )),
    createColumn('Vervangingsjaar', getPropertyName<IGebouwElementHoeveelheid>('vervangingsJaar'), 105),
    createCustomRenderedColumn('Inspectiedatum', getPropertyName<IGebouwElementHoeveelheid>('inspectieDatum'), (item: IGebouwElementHoeveelheid)  => <label>{transformDateString(item.inspectieDatum)}</label>, 105, false),
    createCustomRenderedColumn('Conditie', getPropertyName<IGebouwElementHoeveelheid>('conditie'), (item: IGebouwElementHoeveelheid)  => <Conditie conditie={item.conditie} />, undefined, false),
    createColumn('Element-toelichting', getPropertyName<IGebouwElementHoeveelheid>('toelichting'), 'L'),
  ]
  return (
    <>
      <EditPanelGebouwHoeveelheid isOpen={isPanelOpen} dismissPanel={dismissPanel} itemId={selectedItemId} editMode={props.editMode} />
      <div style={{ height: '78.7vh', position: 'relative' }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            columns={columns}
            items={props.hoeveelheden ?? []}
            selectionMode={SelectionMode.none}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true} stickyBackgroundColor="transparent">
                  <div>{defaultRender && defaultRender(headerProps)}</div>
                </Sticky>
              )
            }}
          />
        </ScrollablePane>
      </div>
    </>
  )
}

export default HoeveelhedenList
