import {Dropdown, PanelType, Text} from '@fluentui/react'
import React, {useEffect, useState} from 'react'
import {z} from 'zod'
import {GebouwdeelAddOrUpdateDto, zodiosHooks} from "../../../../api/ApiClient";
import useRoles from "../../../../services/UseRoles";
import {getTitleAndMessage} from "../../../../services/HandleError";
import {OkCancelButtonStack} from "../../../../components/OkCancelButtonStack/OkCancelButtonStack";
import FluentPanel from "../../../../components/FluentPanel/FluentPanel";
import {ExplainCard} from "../../../../components/ExplainCard/ExplainCard";

type gebouwDeelDetailsType = z.infer<typeof GebouwdeelAddOrUpdateDto>;

export interface IGDBestemmingPanelProps {
  isOpen: boolean
  dismissPanel: () => void
  gebouwDeelDetails?: gebouwDeelDetailsType
  invalidate: () => void
}

export const GebouwDeelBestemmingPanel = (props: IGDBestemmingPanelProps) => {
  const {data: bestemmingOptions} = zodiosHooks.useGetBestemmingOpties({}, {enabled: true})

  const {isVastgoedbeheer} = useRoles()
  const [error, setError] = useState<string>()
  const [bestemmingId, setBestemmingId] = useState<number>(props.gebouwDeelDetails?.bestemmingId ?? 0)

  const {mutate: updateBestemming, isLoading: isSubmitting} = zodiosHooks.usePutGebouwdelenIdbestemmingBestemmingid(
    {
      params: {
        id: props.gebouwDeelDetails?.gebouwDeelId ?? 0,
        bestemmingid: bestemmingId
      }
    }, {
      onSuccess: () => {
        props.invalidate()
        props.dismissPanel()
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    updateBestemming(undefined);
  };

  useEffect(() => {
    if (!props.isOpen) return
    setError(undefined)
    setBestemmingId(props.gebouwDeelDetails?.bestemmingId ?? 0)
  }, [props.isOpen])

  if (props.gebouwDeelDetails === undefined) {
    return (<></>)
  }

  return (
    <FluentPanel type={PanelType.medium} onDismiss={props.dismissPanel}
                 isOpen={props.isOpen} error={error} customWidth={'60%'} headerText={'Wijzigen Bestemming'}>
      <ExplainCard type={'Warning'} headerText={'Het volgende gaat u hiermee wijzigen:'}>
        <Text>
          Het wijzigen van de bestemming op gebouwdeel niveau zorgt ervoor dat de bestemming wordt gewijzigd
          voor het gebouwdeel en alle aan het gebouwdeel gerelateerde vastgoedeenheden.
        </Text>
        <Text>
          Bestaande waarden op vastgoedeenheid niveau worden hiermee overschreven.
          Het is achteraf wel mogelijk om voor individuele vastgoedeenheden de bestemming te wijzigen
          binnen de wijzigen functionaliteit van een vastgoedeenheid.
        </Text>        
      </ExplainCard>
      <Dropdown
        disabled={!isVastgoedbeheer}
        options={bestemmingOptions ?? []}
        label="Bestemming"
        selectedKey={bestemmingId}
        onChange={(e, option) => setBestemmingId((option?.key ?? 0) as number)}
      />
      <OkCancelButtonStack
        isSubmitting={isSubmitting}
        isLoading={false}
        defaultButtonLabel={'Uitvoeren'}
        isWriter={isVastgoedbeheer}
        onOkClick={handleSubmit}
        onCancelClick={props.dismissPanel}
      />
    </FluentPanel>      
  )
}