import React, { FC, useMemo, useState } from 'react'
import {
  CommandBar,
  DetailsList,
  IColumn,
  ICommandBarItemProps, IObjectWithKey,
  Link,
  ScrollablePane,
  Selection,
  SelectionMode
} from '@fluentui/react'
import commandBarStyles from 'styles/commandBarStyles'
import { getPropertyName } from 'lib/interfaceUtils'
import { useBoolean } from '@fluentui/react-hooks'
import SelectAdressenPanel from './selectAdressenPanel'
import { createCommandButton } from 'lib/commandBarHelper'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { useNavigate } from 'react-router-dom'
import { PageIdDefinition, PAGES } from 'pages'
import { useWarningDialog } from 'components/WarningDialog'
import { z } from 'zod'
import { VgeDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type IVge = z.infer<typeof VgeDto>;

interface IGebouwdeelAdressenProps {
  gebouwId: number
  gebouwdeelId: number
}

const GebouwdeelAdressen: FC<IGebouwdeelAdressenProps> = ({ gebouwId, gebouwdeelId }) => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const navigate = useNavigate()
  const [error, setError] = useState<string>()

  const { data: selectedGebouwen, invalidate: refetchSelectedGebouwen } = zodiosHooks.useGetGebouwendetailsId({
    params: {
      id: gebouwId
    }
  }, { enabled: gebouwId !== undefined })

  const { mutate: postVgesToGebouwdeel } = zodiosHooks.usePostGebouwdelenVge({},
    {
      onSuccess: refetchSelectedGebouwen,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: deleteVgesFromGebouwdeel } = zodiosHooks.useDeleteGebouwdelenVge({},
    {
      onSuccess: refetchSelectedGebouwen,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const [WarningDialog, setDialogSubText, showDialog] = useWarningDialog()
  const gebouwdeel = selectedGebouwen?.gebouwDelen?.filter(v => v.id === gebouwdeelId)[0]

  const [selection] = useState(() => {
    const s = new Selection<IVge>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.id
    })

    return s
  })

  const CheckForServiceadres = (vge: IVge, items: any) => {
    if (gebouwdeel?.serviceadresVge === vge.id) {
      setDialogSubText('Vastgoedeenheid kan niet worden ontkoppeld aangezien deze wordt gebruikt als serviceadres')
      showDialog(true)
      deleteVgesFromGebouwdeel({ gebouwdeelId: gebouwdeel?.id ?? 0, vgeIds: items.map(v => v.id) as number[] })
    }else {
      deleteVgesFromGebouwdeel({ gebouwdeelId: gebouwdeel?.id ?? 0, vgeIds: items.map(v => v.id) as number[] })
    }
  }

  const AddToSelection = () => {
    openPanel()
  }

  const RemoveFromSelection = () => {
    if (selection.count > 0) {
      const items = selection.getSelection()

      const VgesToDelete = gebouwdeel?.vgesMetAdres?.filter(vge => {
        return items.some(item => {
          return item.id === vge.id
        })
      })

      VgesToDelete?.forEach(vge => {
        CheckForServiceadres(vge, items)
      })
    }
  }

  const onConnectVges = (items: number[]) => {
    postVgesToGebouwdeel({ gebouwdeelId: gebouwdeel?.id!, vgeIds: items })
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [createCommandButton('Koppelen', 'PlugConnected', AddToSelection), createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection)],
    []
  )

  const handleItemInvoked = ({ id }: IVge) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id.toString()))
  }

  const columns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'XL'),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
    createColumn('Postcode', getPropertyName<IVge>('pc')),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<IVge>('str'),
      (item: IVge) => (
        <Link key={item.str} onClick={() => handleItemInvoked(item)}>
          {item.str}
        </Link>
      ),
      'XL',
      false
    ),
    createColumn('Huisnr.', getPropertyName<IVge>('nr')),
    createColumn('Huislet.', getPropertyName<IVge>('ltr')),
    createColumn('Toev.', getPropertyName<IVge>('nrT')),
    createColumn('Locatieaanduiding', getPropertyName<IVge>('locatieaanduiding'), 'L'),
    createColumn('Gebouwdeel', getPropertyName<IVge>('gdlCd')),
    createCustomRenderedColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125, false),
    createColumn('Beheerder', getPropertyName<IVge>('bn'), 'XL'),
    createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'S')
  ]

  return (
    <>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      {WarningDialog}
      <ErrorMessageBar error={error} />
      <SelectAdressenPanel dismissPanel={dismissPanel} isOpen={isPanelOpen} onConnectVges={onConnectVges} />
      <div style={{ height: '50vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            selection={selection as Selection<IObjectWithKey>}
            styles={{ root: { fontSize: '14px' } }}
            items={gebouwdeel?.vgesMetAdres ?? []}
          ></DetailsList>
        </ScrollablePane>
      </div>
    </>
  )
}

export default GebouwdeelAdressen
