import React, { useCallback, useMemo, useState } from 'react'
import { CommandBar, IColumn, IObjectWithKey, Link, Selection, SelectionMode } from '@fluentui/react'
import commandBarStyles from 'styles/commandBarStyles'
import { IVge, sortOverigGebouwdVges } from 'features/vge/vgeSlice'
import { getPropertyName } from 'lib/interfaceUtils'
import { useBoolean } from '@fluentui/react-hooks'
import SelectAdressenPanel from './selectAdressenPanel'
import { createCommandButton } from 'lib/commandBarHelper'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { PageIdDefinition, PAGES } from 'pages'
import { useNavigate } from 'react-router-dom'
import { zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgeHistorischPanel } from '../../../vge/VgeHistorischPanel'
import FluentTable from '../../../../components/FluentTable'

interface IGebouwAdressenProps {
  gebouwId: number
  updateSelectedGebouw: any
  isTerrein: boolean;
}

const GebouwPercelen: React.FC<IGebouwAdressenProps> = ({ gebouwId, updateSelectedGebouw, isTerrein }) => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [error, setError] = useState<string>()
  const navigate = useNavigate()
  const [inHistorischMode, { setTrue: openHistorischPanel, setFalse: dismissHistorischPanel }] = useBoolean(false)
  const [historischButton, { setTrue: showHistorischButton, setFalse: hideHistorischButton }] = useBoolean(false)
  const [getSelectedIds, setSelectedIds] = useState([] as number[])

  const onModified = useCallback(() => {
    dismissHistorischPanel()
  }, [dismissHistorischPanel])

  const { data: gebouw, invalidate: fetchGebouwen } = zodiosHooks.useGetGebouwendetailsId({
    params: { id: gebouwId }
  }, { enabled: gebouwId !== 0 })

  const { mutate: addPercelenToGebouw } = zodiosHooks.usePostGebouwenVge({}, {
    onSuccess: fetchGebouwen,
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteVgesFromGebouw } = zodiosHooks.useDeleteGebouwenVge({}, {
    onSuccess: fetchGebouwen,
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const [selection] = useState(() => new Selection<IObjectWithKey>({
    selectionMode: SelectionMode.multiple,
    onSelectionChanged: () => {
      return selection.count
    },
    getKey: item => (item as IVge).id
  }))
  
  const onSelectionChanged = (ids: string[]) => {
    const selectedIds = ids.map(id => parseInt(id))
    selectedIds.length > 0 ? showHistorischButton() : hideHistorischButton()
    setSelectedIds(selectedIds)
  }

  const AddToSelection = useCallback(() => {
    updateSelectedGebouw()
    openPanel()
  }, [updateSelectedGebouw, openPanel])

  const RemoveFromSelection = useCallback(() => {
    if (selection.count > 0) {
      const items = selection.getSelection()
      updateSelectedGebouw()
      let ids: number[] = []
      for (const item of items) {
        const vge = item as IVge
        ids.push(vge.id)
      }

      deleteVgesFromGebouw({
        gebouwId: gebouwId,
        vgeIds: ids
      })
    }
  }, [updateSelectedGebouw, deleteVgesFromGebouw, gebouwId])

  const onLinkClick = useCallback((id: string) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id.toString()))
  }, [navigate])

  const selectCommandBarItems = useMemo(() => [
    createCommandButton('Koppelen', 'PlugConnected', AddToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection),
    createCommandButton('Historisch maken', 'Edit', openHistorischPanel, historischButton)
  ], [AddToSelection, RemoveFromSelection, historischButton])

  const onConnectVges = useCallback((items: number[]) => {
    addPercelenToGebouw({ gebouwId: gebouwId, vgeIds: items, linkVgeContracten: true })
  }, [addPercelenToGebouw, gebouwId])

  const terreinColumns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'XL', false),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl'), 'M', false),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<IVge>('str'),
      (item: IVge) => (
        <Link key={item.str} onClick={() => onLinkClick(item.id.toString())}>
          {item.str}
        </Link>
      ),
      250,
      false
    ),
    createColumn('Locatieaanduiding', getPropertyName<IVge>('locatieaanduiding'), 'L'),
    createColumn('Terreinclassificatie', getPropertyName<IVge>('trnNm'), 240, false),
    createColumn('Beheerder', getPropertyName<IVge>('bn'), 'XL'),
    createColumn('Kadastrale aand. vlak', getPropertyName<IVge>('kadV'), 'L', false),
    createColumn('Grootte', getPropertyName<IVge>('grt'), 75, false),
    createColumn('Opmerking', getPropertyName<IVge>('opm'), 'L', false),
    createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'S')
  ]

  const columnsOverigGebouwd: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'XL'),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl'), 90),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<IVge>('str'),
      (item: IVge) => (
        <Link key={item.str} onClick={() => onLinkClick(item.id.toString())}>
          {item.str}
        </Link>
      ),
      'L',
      false
    ),
    createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 115),
    createColumn('Locatieaanduiding', getPropertyName<IVge>('locatieaanduiding'), 'XXL'),
    createColumn('Gebouwdeel', getPropertyName<IVge>('gdlCd'), 90),
    createCustomRenderedColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 110, false),
    createColumn('Gerelateerd', getPropertyName<IVge>('rel'), 'L'),
    createColumn('Beheerder', getPropertyName<IVge>('bn'), 175),
    createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'S')
  ]

  const columns: IColumn[] = isTerrein ? terreinColumns : columnsOverigGebouwd

  const filteredItems = useMemo(() => {
    if (!gebouw?.vgesZonderAdres) return []
    return isTerrein
      ? gebouw.vgesZonderAdres.filter(x => x.srt === 'TER')
      : sortOverigGebouwdVges(gebouw.vgesZonderAdres.filter(x => x.srt !== 'TER'))
  }, [gebouw?.vgesZonderAdres, isTerrein])

  return (
    <>
      <VgeHistorischPanel onModified={onModified} dismissPanel={dismissHistorischPanel} isOpen={inHistorischMode}
                          vgeIds={getSelectedIds} />
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      <ErrorMessageBar error={error} />
      <SelectAdressenPanel dismissPanel={dismissPanel} isOpen={isPanelOpen} onConnectVges={onConnectVges}
                           withoutAdres={true} isTerrein={isTerrein} />
      <div style={{ height: '60vh', position: 'relative' }}>
        <FluentTable
          columns={columns}
          items={filteredItems}
          onItemInvoked={() => null}
          onGetKey={(item) => item.id}
          selection={selection}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </>
  )
}

export default React.memo(GebouwPercelen)