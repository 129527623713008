import React, { useEffect, useState } from 'react'
import { IDropdownOption, MessageBar, MessageBarType, Panel, PanelType } from '@fluentui/react'
import { useSelector } from 'react-redux'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtDropdown from '../../../../components/FluentDropdown'
import { serviceadresOptions } from '../../../vge/vgeSlice'
import { useForm } from 'react-hook-form'
import { getGebouwenClustersAsOptions } from '../gebouwenclusters/gebouwenClustersSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { GebouwAddOrUpdateDto, GebouwDetailsDto, VgeDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { availableUrls } from '../../../../routing/AvailableUrls'
import { zodResolver } from '@hookform/resolvers/zod'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import useRoles from '../../../../services/UseRoles'

type IGebouwDetailsDto = z.infer<typeof GebouwDetailsDto>;
type CreateUpdateType = z.infer<typeof GebouwAddOrUpdateDto>;
type VgeDTO = z.infer<typeof VgeDto>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  gebouw?: IGebouwDetailsDto
  invalidate: () => void
}

export const GebouwEditPanel: React.FC<IEditPanelProps> = ({ gebouw, dismissPanel, isOpen, invalidate }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [error, setError] = useState<string>()
  const [selectedportefeuille, setSelectedportefeuille] = useState<number>(0)
  const { isVastgoedbeheer } = useRoles()

  const gebouwenClusterOptions = useSelector(getGebouwenClustersAsOptions)

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(GebouwAddOrUpdateDto),
    mode: 'all',
    defaultValues: {
      code: gebouw?.code,
      naam: gebouw?.naam,
      gebouwId: 0,
      type: 1,
      omschrijving: gebouw?.omschrijving,
      vastgoedportefeuilleId: 0,
      vastgoeddeelportefeuilleId: 0,
      gebouwenClusterId: gebouw?.gebouwenClusterId,
      registratieniveau: 'STD',
      serviceadresVge: gebouw?.serviceadresVge,
      advertentietekst: gebouw?.advertentietekst!
    } as CreateUpdateType
  })

  const { invalidate: invalidateGebouw } = zodiosHooks.useGetGebouwenId({ params: { id: parseInt(id!) } }, {
    onSuccess: (data) => reset(data),
    enabled: id !== undefined && id !== '0'
  })

  const { data: selectedGebouw } = zodiosHooks.useGetGebouwendetailsId({ params: { id: parseInt(id!) } }, { enabled: id !== undefined && id !== '0' })

  const { mutate: updateGebouwen } = zodiosHooks.usePutGebouwenId(
    { params: { id: parseInt(id!) } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addGebouwen } = zodiosHooks.usePostGebouwen({}, {
      onSuccess: () => {
        navigate(availableUrls.Gebouwen)
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const options: IDropdownOption[] = [
    { key: 1, text: 'Hoogbouw' },
    { key: 2, text: 'Laagbouw' }
  ]

  const registratieOptions: IDropdownOption[] = [
    { key: 'WRK', text: 'Werkelijk' },
    { key: 'STD', text: 'Standaard' }
  ]

  useEffect(() => {
    setSelectedportefeuille(selectedGebouw?.vastgoedportefeuilleId ?? 0)
  }, [selectedGebouw])

  const { data: vastgoedportefeuillen } = zodiosHooks.useGetVastgoedportefeuille()
  const vastgoedportefeuillenOpties = vastgoedportefeuillen?.items?.map((item) => {
    return { key: item.vastgoedportefeuilleId, text: item.naam }
  })

  const { data: vastgoeddeelportefeuillen } = zodiosHooks.useGetVastgoeddeelportefeuille()
  const filteredVastgoeddeelportefeuillen = vastgoeddeelportefeuillen?.items?.filter(item => item.vastgoedportefeuilleId === selectedportefeuille)
  const vastgoeddeelportefeuillenOpties = filteredVastgoeddeelportefeuillen?.map((item) => {
    return { key: item.vastgoeddeelportefeuilleId, text: item.naam }
  })

  const { data: werkgebiedenOpties } = zodiosHooks.useGetWerkgebiedopties()

  const vgesMetAdres = () => {
    const itemsArray = [] as VgeDTO[]
    if (selectedGebouw?.vgesMetAdres!) itemsArray.push(...selectedGebouw?.vgesMetAdres ?? [])
    if (selectedGebouw?.gebouwDelen!) selectedGebouw?.gebouwDelen.forEach(value => itemsArray.push(...value.vgesMetAdres!))
    return itemsArray
  }

  const onCancel = () => {
    invalidate()
    invalidateGebouw()
    dismissPanel()
    setError(undefined)
    if (id === '0') {
      navigate(availableUrls.Gebouwen)
    }
  }

  const handleConfirmClick = () => {
    setError('')
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (id !== '0') {
      data.gebouwId = parseInt(id!)
      updateGebouwen(data)
    } else {
      addGebouwen({ ...data, advertentietekst: data.advertentietekst ?? '' })
    }
  }

  return (
    <Panel type={PanelType.custom} customWidth='60%' headerText={`Gebouw ${id !== '0' ? 'wijzigen' : 'toevoegen'} `}
           isOpen={isOpen} onDismiss={onCancel}
           closeButtonAriaLabel='Sluiten'>
      {error && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={handleConfirmClick}
                    dismissButtonAriaLabel='Close'>
          <b>{error}</b>
        </MessageBar>
      )}
      <br />
      <form onSubmit={handleSubmit(onSubmit, (errors) => {
        setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
        console.log(errors)
      })}>
        <VgtTextField
          label='Code'
          name={getPropertyName<CreateUpdateType>('code')}
          control={control}
          register={register}
          errors={errors}
          required
          labelWidth={'250px'}
        />
        <VgtTextField
          label='Naam'
          name={getPropertyName<CreateUpdateType>('naam')}
          register={register}
          control={control}
          errors={errors}
          required
          labelWidth={'250px'}
        />
        <VgtDropdown
          label='Type bouw'
          name={getPropertyName<CreateUpdateType>('type')}
          register={register}
          control={control}
          errors={errors}
          options={options}
          labelWidth={'250px'}
        />
        <VgtTextField
          label='Omschrijving'
          name={getPropertyName<CreateUpdateType>('omschrijving')}
          control={control}
          errors={errors}
          labelWidth={'250px'}
        />
        <VgtDropdown
          label='Gebouwencluster'
          name={getPropertyName<CreateUpdateType>('gebouwenClusterId')}
          register={register}
          control={control}
          errors={errors}
          options={gebouwenClusterOptions}
          required
          labelWidth={'250px'}
        />
        <VgtDropdown
          label='Service adres'
          name={getPropertyName<CreateUpdateType>('serviceadresVge')}
          defaultSelectedKey={selectedGebouw?.serviceadresVge ? selectedGebouw?.serviceadresVge : 0}
          register={register}
          control={control}
          errors={errors}
          options={serviceadresOptions(vgesMetAdres())}
          labelWidth={'250px'}
        />
        <VgtTextField label='Service e-mailadres' name={getPropertyName<CreateUpdateType>('serviceEmailadres')}
                      control={control}
                      errors={errors} labelWidth={'250px'} />
        <VgtTextField label='Service telefoonnummer' name={getPropertyName<CreateUpdateType>('serviceTelefoonnummer')}
                      control={control} errors={errors} labelWidth={'250px'} />
        <VgtDropdown
          label='Registratieniveau collectieve objecten'
          name={getPropertyName<CreateUpdateType>('registratieniveau')}
          register={register}
          control={control}
          errors={errors}
          options={registratieOptions}
          labelWidth={'250px'}
        />
        <VgtDropdown
          label='Vastgoedportefeuille'
          name={getPropertyName<CreateUpdateType>('vastgoedportefeuilleId')}
          register={register}
          control={control}
          errors={errors}
          options={vastgoedportefeuillenOpties as IDropdownOption[]}
          onChange={(item) => setSelectedportefeuille(item as number)
          }
          labelWidth={'250px'}
        />
        <VgtDropdown
          label='Vastgoeddeelportefeuille'
          name={getPropertyName<CreateUpdateType>('vastgoeddeelportefeuilleId')}
          register={register}
          control={control}
          errors={errors}
          options={vastgoeddeelportefeuillenOpties as IDropdownOption[]}
          labelWidth={'250px'}
        />
        <VgtDropdown
          label='Werkgebied'
          name={getPropertyName<CreateUpdateType>('werkgebiedId')}
          register={register}
          control={control}
          errors={errors}
          options={werkgebiedenOpties as IDropdownOption[]}
          labelWidth={'250px'}
        />
        <VgtTextField
          label='Advertentietekst'
          name={getPropertyName<CreateUpdateType>('advertentietekst')}
          register={register}
          control={control}
          multiline={true}
          errors={errors}
          labelWidth={'250px'}
        />
        {isVastgoedbeheer ?
          <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                               onOkClick={() => handleSubmit(onSubmit)} onCancelClick={onCancel} /> : null}
      </form>
    </Panel>
  )
}