import * as React from 'react'
import Search from '../features/search'
import DimensiesList from '../features/beheer/financieel/dimensies/DimensiesList'
import EenheidDetailSoortList from '../features/beheer/lemdo/eenheidDetailSoort'
import RuimteSoortenList from '../features/beheer/lemdo/ruimteSoort'
import RuimteSoortMatrix from '../features/beheer/lemdo/eenheidDetailSoort/ruimteSoort'
import DefectLocatieList from '../features/beheer/lemdo/defectLocatie'
import DefectlocatieMatrix from '../features/beheer/lemdo/eenheidDetailSoort/defectlocatie'
import Home from '../containers/pages/Home'
import ElementCategorieList from '../features/beheer/lemdo/elementCategorie'
import ElementList from '../features/beheer/lemdo/element'
import LocatieMatrix from '../features/beheer/lemdo/defectLocatie/elementen'
import ElementElementDetailList from '../features/beheer/lemdo/elementElementDetail'
import DefectSoortList from '../features/beheer/lemdo/defectSoort'
import ElementDefectSoortMatrix from '../features/beheer/lemdo/elementDefectSoort'
import NLSFBElementenList from '../features/beheer/lemdo/nlsfb'
import MateriaalSoortList from '../features/beheer/lemdo/materiaalSoort'
import WoningverbeteringList from '../features/beheer/lemdo/woningverbetering'
import OnderhoudsspecialismeList from '../features/beheer/lemdo/onderhoudsspecialisme'
import DefectoorzaakList from '../features/beheer/lemdo/defectoorzaak'
import WoningverbeteringToestemmingList from '../features/beheer/lemdo/woningverbeteringToestemming'
import DefectlocatiesWoningverbetering from '../features/beheer/lemdo/defectlocatiesWoningverbeteringen'
import { VerwarmingList } from '../features/beheer/lemdo/verwarming/VerwarmingList'
import BouwlagenList from '../features/beheer/stamtabellen/bouwlagen'
import TerreinClassificatie from '../features/beheer/lemdo/terreinClassificatie'
import CoenCadCodeList from '../features/beheer/coencad'
import { SleuteltypeList } from '../features/beheer/sleuteltype/SleuteltypeList'
import { MetertypeList } from '../features/beheer/metertype/MetertypeList'
import VastgoedQueries from '../features/beheer/vastgoedQueries'
import BasisRegistratiesSearch from '../features/beheer/tools/basisregistratiessearch'
import GebouwenClustersList from '../features/beheer/fysiek/gebouwenclusters'
import { VheList } from '../features/vhe/VheList'
import DienstenComponentList from '../features/beheer/lemdo/dienstenComponent'
import OnderhoudsTaakList from '../features/beheer/lemdo/onderhoudsTaakErp'
import RuimtesoortErpList from '../features/beheer/lemdo/ruimtesoortErp'
import {TenantTaakList} from '../features/beheer/lemdo/tenantTaak/TenantTaakList'
import TenantRuimteSoortList from '../features/beheer/lemdo/tenantRuimteSoorten'
import KostensoortList from '../features/beheer/lemdo/kostensoort'
import GarantieList from '../features/beheer/lemdo/garantie'
import TenantKostensoortBetalerList from '../features/beheer/lemdo/tenantKostensoortBetaler'
import TenantWoningverbeteringList from '../features/beheer/lemdo/tenantWoningverbetering'
import { RechtspersoonList } from '../features/beheer/relaties/rechtspersoon/rechtspersoonList'
import VveList from '../features/beheer/relaties/vve/VveList'
import { ContactgegevenList } from '../features/beheer/relaties/contactgegevens/ContactgegevenList'
import Importeren from '../features/beheer/imports'
import Exporteren from '../features/beheer/imports/exporteren'
import Verwerkingen from '../features/beheer/verwerkingen'
import InvokeVerwerking from '../features/beheer/verwerkingen/Invoke'
import OnderhoudsContractGrid from '../features/beheer/onderhoudscontract'
import OnderhoudsbedrijfTyperingList from '../features/beheer/onderhoudsbedrijfTypering'
import { ContactCategorieList } from '../features/beheer/contactcategorien/ContactCategorieList'
import BegrippenLijst from '../features/beheer/begrippenlijst'
import { availableUrls } from './AvailableUrls'
import BeheerSelector from '../features/beheer/beheerSelector'
import Gebouwen from '../features/beheer/fysiek/gebouwen'
import GebouwEdit from '../features/beheer/fysiek/gebouwen/gebouwEdit'
import EditGebouwenCluster from '../features/beheer/fysiek/gebouwenclusters/editGebouwenCluster'
import { RechtspersoonNew } from '../features/beheer/relaties/rechtspersoon/RechtspersoonNew'
import { RechtspersoonEdit } from '../features/beheer/relaties/rechtspersoon/RechtspersoonEdit'
import VveDetails from '../features/beheer/relaties/vve/VveDetails'
import EenheidSoortList from '../features/beheer/lemdo/eenheidsoort'
import ElementDetailList from '../features/beheer/lemdo/elementDetail/ElementDetailList'
import VgeEdit from '../features/vge/vgeEdit'
import VgeFromVhe from '../features/vge/VgeFromVhe'
import VerwerkingsRegels from '../features/beheer/verwerkingen/verwerkingsregels'
import ImportResults from '../features/beheer/imports/taken'
import ImportElementen from '../features/beheer/imports/elementen'
import ContractEdit from '../features/beheer/onderhoudscontract/contractEdit'
import DimensiewaardenList from '../features/beheer/financieel/dimensiewaarden/DimensiewaardenList'
import DimensiewaardenNew from '../features/beheer/financieel/dimensiewaarden/DimensiewaardenNew'
import DimensiewaardenEdit from '../features/beheer/financieel/dimensiewaarden/DimensiewaardenEdit'
import DimensiesNew from '../features/beheer/financieel/dimensies/DimensiesNew'
import DimensiesEdit from '../features/beheer/financieel/dimensies/DimensiesEdit'
import { VheNew } from '../features/vhe/VheNew'
import { VheEdit } from '../features/vhe/VheEdit'
import SearchResults from '../features/vge/searchResults'
import Gebouwdelen from '../features/beheer/fysiek/gebouwdelen'
import { AvailableRoles } from '../services/UseRoles'
import { WerkzaamhedenList } from '../features/beheer/werkzaamheden/WerkzaamhedenList'
import DocumentClassificationList from '../features/beheer/documents/DocumentClassificationList'
import Categorieen from '../features/beheer/categorieen'
import { InspectietakenList } from '../features/beheer/lemdo/inspectietaken/InspectietakenList'
import { OpnamedetailCartotheek } from '../features/beheer/opnamedetailCartotheek'
import { TenantInspectietakenList } from '../features/beheer/inspectie/TenantInspectietakenList'
import { VoorzieningenList } from '../features/beheer/Voorzieningen/VoorzieningenList'
import Gebouwdeel from '../features/beheer/fysiek/gebouwen/gebouwdeel'
import { InspectieSjabloonList } from '../features/beheer/inspectieSjabloon/InspectieSjabloonList'
import { InspectieSjabloonEdit } from '../features/beheer/inspectieSjabloon/InspectieSjabloonEdit'
import { InspectieSjabloonNew } from '../features/beheer/inspectieSjabloon/InspectieSjabloonNew'
import { FonticoonList } from '../features/beheer/lemdo/fonticoon/FonticoonList'
import { HuurcontractbepalingList } from '../features/beheer/huurcontractbepaling/HuurcontractbepalingList'
import { HuurcontractbepalingNew } from '../features/beheer/huurcontractbepaling/HuurcontractbepalingNew'
import { HuurcontractbepalingEdit } from '../features/beheer/huurcontractbepaling/HuurcontractbepalingEdit'
import { VastgoedportefeuilleList } from '../features/beheer/vastgoedportefeuille/VastgoedportefeuilleList'
import { VastgoeddeelportefeuilleList } from '../features/beheer/vastgoeddeelportefeuille/VastgoeddeelportefeuilleList'
import { HuurcontractbepalingShow } from '../features/beheer/huurcontractbepaling/HuurcontractbepalingShow'
import {
    DefectoorzaakKostensoortList
} from '../features/beheer/lemdo/defectoorzaakKostensoort/DefectoorzaakKostensoortList'
import { WerkgebiedList } from '../features/beheer/werkgebied/WerkgebiedList'
import { TakenList } from '../features/beheer/lemdo/taken/TakenList'
import { SystemSettingsView } from '../features/System/SystemSettingsView'

export interface Route {
  displayName: string;
  icon?: string;
  showInNav: boolean;
  path: keyof typeof availableUrls;
  allowedRoles: (keyof typeof AvailableRoles)[];
  component: React.ReactElement;
}

export interface RouteGroup {
  displayName: string;
  key: string;
  RouteGroups?: Route[];
  TopLevelGroup?: RouteGroup[];
}

const routes: (Route | RouteGroup)[] = [
    {
        displayName: 'Startpagina',
        icon: 'Home',
        showInNav: true,
        allowedRoles: ['Data.Reader'],
        path: 'Startpagina',
        component: <Home/>
    },
    {
        displayName: 'Zoeken',
        icon: 'Search',
        showInNav: true,
        allowedRoles: ['Data.Reader'],
        path: 'Search',
        component: <Search />
    },
    {
        displayName: 'Stamgegevens',
        key: 'stamgegevens',
        TopLevelGroup: [
            {
                displayName: 'Begrippenlijst',
                key: 'BegrippenlijstMenu',
                RouteGroups: [
                    {
                        path: 'BegrippenLijst',
                        icon: 'EntitlementPolicy',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Begrippenlijst',
                        showInNav: true,
                        component: <BegrippenLijst/>
                    },
                    {
                        displayName: 'BegrippenLijstViewOnly',
                        path: 'BegrippenLijstViewOnly',
                        allowedRoles: ['VgtAdministrator'],
                        showInNav: false,
                        component: <BegrippenLijst/>
                    }
                ]
            },
            {
                displayName: 'Integraties',
                key: 'Integratiesgroup',
                RouteGroups: [
                    {
                        path: 'Coencad',
                        allowedRoles: ['VgtAdministrator'],
                        icon: 'ButtonControl',
                        displayName: 'Coencad',
                        showInNav: true,
                        component: <CoenCadCodeList/>
                    }
                ]
            },
            {
                displayName: 'Klantdialoog',
                key: 'stamgegevensklantdialooggroup',
                RouteGroups: [
                    {
                        path: 'Eenheidsoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Eenheidsoorten',
                        icon: 'ViewList',
                        showInNav: true,
                        component: <EenheidSoortList/>
                    },
                    {
                        path: 'Eenheiddetailsoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Eenheiddetailsoorten',
                        icon: 'ClipboardList',
                        showInNav: true,
                        component: <EenheidDetailSoortList/>
                    },
                    {
                        path: 'Ruimtesoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Ruimtesoorten',
                        icon: 'ClipboardList',
                        showInNav: true,
                        component: <RuimteSoortenList/>
                    },
                    {
                        path: 'EenheiddetailsoortenRuimtesoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Eenheiddetailsoorten || Ruimtesoorten',
                        icon: 'ClipboardList',
                        showInNav: true,
                        component: <RuimteSoortMatrix/>
                    },
                    {
                        path: 'Defectlocaties',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectlocaties',
                        icon: 'ClipboardList',
                        showInNav: true,
                        component: <DefectLocatieList/>
                    },
                    {
                        path: 'EenheiddetailsoortenDefectlocatiesBinnen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectlocaties binnen',
                        icon: 'ClipboardList',
                        showInNav: true,
                        component: <DefectlocatieMatrix binnen={true}/>
                    },
                    {
                        path: 'EenheiddetailsoortenDefectlocatiesBuiten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectlocaties buiten',
                        icon: 'ClipboardList',
                        showInNav: true,
                        component: <DefectlocatieMatrix binnen={false}/>
                    },
                    {
                        path: 'ElementCategorieen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Elementcategorieën',
                        icon: 'AnalyticsReport',
                        showInNav: true,
                        component: <ElementCategorieList/>
                    },
                    {
                        path: 'Elementen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Elementen',
                        icon: 'DelveAnalytics',
                        showInNav: true,
                        component: <ElementList/>
                    },
                    {
                        path: 'DefectlocatiesElementen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectlocaties || Elementen',
                        icon: 'MapPinSolid',
                        showInNav: true,
                        component: <LocatieMatrix/>
                    },
                    {
                        path: 'Elementdetails',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Elementdetails',
                        icon: 'KnowledgeArticle',
                        showInNav: true,
                        component: <ElementDetailList/>
                    },
                    {
                        path: 'ElementdetailsElementen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Cartotheek-items',
                        icon: 'ContextMenu',
                        showInNav: true,
                        component: <ElementElementDetailList/>
                    },
                    {
                        path: 'Defectsoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectsoorten',
                        icon: 'ConstructionCone',
                        showInNav: true,
                        component: <DefectSoortList/>
                    },
                    {
                        path: 'ElementDefectsoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Cartotheek-item || Defectsoort',
                        icon: 'ReportWarning',
                        showInNav: true,
                        component: <ElementDefectSoortMatrix/>
                    },
                    {
                        path: 'Element',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Cartotheek-item || Defectsoort || Reparatieboom-taken',
                        icon: 'BulletedTreeList',
                        showInNav: true,
                        component: <RuimteSoortMatrix/>
                    },
                    {
                        path: 'nlsfb',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'NL SfB elementen',
                        icon: 'WebAppBuilderFragment',
                        showInNav: true,
                        component: <NLSFBElementenList/>
                    },
                    {
                        path: 'Inspectietaken',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Inspectietaken',
                        icon: 'RecurringTask',
                        showInNav: true,
                        component: <InspectietakenList/>
                    },
                    {
                        path: 'Taken',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Reparatieboom-taken',
                        icon: 'AllApps',
                        showInNav: true,
                        component: <TakenList />
                    },
                    {
                        path: 'Materiaalsoorten',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Materiaalsoorten',
                        icon: 'DiffSideBySide',
                        showInNav: true,
                        component: <MateriaalSoortList/>
                    },
                    {
                        path: 'Woningverbetering',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Woningverbeteringen',
                        icon: 'BuildQueueNew',
                        showInNav: true,
                        component: <WoningverbeteringList/>
                    },
                    {
                        path: 'Onderhoudsspecialismen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Onderhoudsspecialismen',
                        icon: 'ExternalBuild',
                        showInNav: true,
                        component: <OnderhoudsspecialismeList/>
                    },
                    {
                        path: 'Defectoorzaak',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectoorzaken',
                        icon: 'CalendarReply',
                        showInNav: true,
                        component: <DefectoorzaakList/>
                    },
                    {
                        path: 'WoningverbeteringToestemmingcodes',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Woningverbetering || Toestemmingcodes',
                        icon: 'RealEstate',
                        showInNav: true,
                        component: <WoningverbeteringToestemmingList/>
                    },
                    {
                        path: 'DefectlocatiesWoningverbetering',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Defectlocaties || Woningverbetering',
                        icon: 'RealEstate',
                        showInNav: true,
                        component: <DefectlocatiesWoningverbetering/>
                    },
                    {
                        path: 'Werkzaamheden',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Werkzaamheden',
                        icon: 'AccountActivity',
                        showInNav: true,
                        component: <WerkzaamhedenList/>
                    }
                ]
            },
            {
                displayName: 'Referentiedata',
                key: 'Referentiedata',
                RouteGroups: [
                    {
                        path: 'Fonticoon',
                        allowedRoles: ['VgtAdministrator'],
                        icon: 'Font',
                        displayName: 'Fonticoon',
                        showInNav: true,
                        component: <FonticoonList />
                    },
                    {
                        path: 'Bouwlagen',
                        allowedRoles: ['VgtAdministrator'],
                        icon: 'MapLayers',
                        displayName: 'Bouwlagen',
                        showInNav: true,
                        component: <BouwlagenList/>
                    },
                    {
                        path: 'Contactcategorien',
                        icon: 'ContactCardSettingsMirrored',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Contactcategoriën',
                        showInNav: true,
                        component: <ContactCategorieList/>
                    },
                    {
                        path: 'ClassificatieMedia',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Classificatie Media',
                        icon: 'DocumentManagement',
                        showInNav: true,
                        component: <DocumentClassificationList/>
                    },
                    {
                        path: 'TyperingOnderhoudsbedrijven',
                        icon: 'CompanyDirectory',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Typering onderhoudsbedrijven',
                        showInNav: true,
                        component: <OnderhoudsbedrijfTyperingList/>
                    },
                    {
                        path: 'TerreinClassificatie',
                        allowedRoles: ['VgtAdministrator'],
                        icon: 'World',
                        displayName: 'TerreinClassificatie',
                        showInNav: true,
                        component: <TerreinClassificatie/>
                    }
                ]
            },
            {
                displayName: 'Sleutels en meters',
                key: 'sleutelsEnMetersGroup',
                RouteGroups: [
                    {
                        path: 'Sleuteltypes',
                        allowedRoles: ['VgtAdministrator'],
                        icon: 'LinkedDatabase',
                        displayName: 'Sleuteltypes',
                        showInNav: true,
                        component: <SleuteltypeList/>
                    },
                    {
                        path: 'Metertypes',
                        icon: 'LinkedDatabase',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Metertypes',
                        showInNav: true,
                        component: <MetertypeList/>
                    }
                ]
            },
            {
                displayName: 'Woningwaardering',
                key: 'woningwaarderinggroup',
                RouteGroups: [
                    {
                        path: 'Voorzieningen',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Voorzieningen',
                        icon: 'Breakfast',
                        showInNav: true,
                        component: <VoorzieningenList/>
                    },
                    {
                        path: 'Verwarming',
                        allowedRoles: ['VgtAdministrator'],
                        displayName: 'Verwarming',
                        icon: 'ProductVariant',
                        showInNav: true,
                        component: <VerwarmingList/>
                    }
                ]
            }
        ]
    },
    {
        displayName: 'Beheergegevens',
        key: 'beheergegevens',
        TopLevelGroup: [
            {
                displayName: 'Aansluitingscontroles',
                key: 'Aansluitingscontroles',
                RouteGroups: [
                    {
                        path: 'Verhuuradministratie',
                        icon: 'SearchData',
                        allowedRoles: ['Vastgoedbeheer'],
                        displayName: 'Verhuuradministratie',
                        showInNav: true,
                        component: <VastgoedQueries/>
                    },
                    {
                        path: 'BasisRegistratiesSearch',
                        icon: 'FabricFolderSearch',
                        allowedRoles: ['Administrator'],
                        displayName: 'Basisregistraties',
                        showInNav: true,
                        component: <BasisRegistratiesSearch/>
                    },
                    {
                        path: 'BasisRegistratiesDetails',
                        icon: 'Search',
                        allowedRoles: ['Administrator'],
                        displayName: 'Basisregistraties',
                        showInNav: false,
                        component: <BasisRegistratiesSearch/>
                    }
                ]
            },
            {
                displayName: 'Indelen fysiek bezit',
                key: 'indelenfysiekbezitgroup',
                RouteGroups: [
                    {
                        path: 'Gebouwenclusters',
                        icon: 'Sections',
                        allowedRoles: ['Vastgoedbeheer', 'Data.Reader'],
                        displayName: 'Gebouwenclusters',
                        showInNav: true,
                        component: <GebouwenClustersList/>
                    },
                    {
                        path: 'Gebouwen',
                        displayName: 'Gebouwen',
                        icon: 'CityNext',
                        showInNav: true,
                        allowedRoles: ['Vastgoedbeheer', 'Data.Reader'],
                        component: <Gebouwen/>
                    },
                    {
                        path: 'Gebouwdelen',
                        icon: 'CityNext',
                        displayName: 'Gebouwdelen',
                        allowedRoles: ['Vastgoedbeheer', 'Data.Reader'],
                        showInNav: true,
                        component: <Gebouwdelen/>
                    },
                    {
                        displayName: 'GebouwEdit',
                        path: 'GebouwEdit',
                        showInNav: false,
                        allowedRoles: ['Vastgoedbeheer', 'Data.Reader'],
                        component: <GebouwEdit/>
                    },
                    {
                        displayName: 'GebouwenClusterEdit',
                        path: 'GebouwenClusterEdit',
                        showInNav: false,
                        allowedRoles: ['Vastgoedbeheer', 'Data.Reader'],
                        component: <EditGebouwenCluster/>
                    },
                    {
                        displayName: 'Gebouwdeel',
                        path: 'Gebouwdeel',
                        showInNav: false,
                        allowedRoles: ['Vastgoedbeheer', 'Data.Reader'],
                        component: <Gebouwdeel/>
                    }
                ]
            },
            {
                displayName: 'Vastgoedportefeuille',
                key: 'vastgoedportefeuille',
                RouteGroups: [
                    {
                        path: 'Vastgoedportefeuille_List',
                        displayName: 'Vastgoedportefeuille',
                        icon: 'TextDocument',
                        showInNav: true,
                        allowedRoles: ['Vastgoedbeheer'],
                        component: <VastgoedportefeuilleList />
                    },
                    {
                        path: 'Vastgoeddeelportefeuille_List',
                        displayName: 'Vastgoeddeelportefeuille',
                        icon: 'Documentation',
                        showInNav: true,
                        allowedRoles: ['Vastgoedbeheer'],
                        component: <VastgoeddeelportefeuilleList/>
                    },
                ]
            },
            {
                displayName: 'Verhuur',
                key: 'verhuurgroup',
                RouteGroups: [
                    {
                        path: 'Vhes_List',
                        displayName: 'Verhuureenheden',
                        icon: 'Sections',
                        showInNav: true,
                        allowedRoles: ['Verhuur'],
                        component: <VheList/>
                    },
                    {
                        displayName: 'Vhes_New',
                        path: 'Vhes_New',
                        showInNav: false,
                        allowedRoles: ['Verhuur'],
                        component: <VheNew/>
                    },
                    {
                        displayName: 'Vhes_Edit',
                        path: 'Vhes_Edit',
                        showInNav: false,
                        allowedRoles: ['Verhuur'],
                        component: <VheEdit/>
                    },
                    {
                        path: 'Huurcontractbepaling_List',
                        displayName: 'Huurcontractbepalingen',
                        icon: 'DocLibrary',
                        allowedRoles: ['Verhuur'],
                        showInNav: true,
                        component: <HuurcontractbepalingList />
                    },
                    {
                        displayName: 'Huurcontractbepaling_New',
                        path: 'Huurcontractbepaling_New',
                        showInNav: false,
                        allowedRoles: ['Verhuur'],
                        component: <HuurcontractbepalingNew />
                    },
                    {
                        displayName: 'Huurcontractbepaling_Edit',
                        path: 'Huurcontractbepaling_Edit',
                        showInNav: false,
                        allowedRoles: ['Verhuur'],
                        component: <HuurcontractbepalingEdit />
                    },
                    {
                        displayName: 'Huurcontractbepaling_Show',
                        path: 'Huurcontractbepaling_Show',
                        showInNav: false,
                        allowedRoles: ['Data.Reader'],
                        component: <HuurcontractbepalingShow />
                    },
                ]
            },
            {
                displayName: 'Klantdialoog',
                key: 'klantdialooggroup',
                RouteGroups: [
                    {
                        path: 'Dienstencomponenten',
                        displayName: 'Dienstencomponenten',
                        icon: 'WebComponents',
                        showInNav: true,
                        allowedRoles: ['Reparatieboom'],
                        component: <DienstenComponentList/>
                    },
                    {
                        path: 'OnderhoudstakenERP',
                        displayName: 'Onderhoudstaken ERP',
                        icon: 'ProcessMetaTask',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <OnderhoudsTaakList/>
                    },
                    {
                        path: 'RuimtesoortenERP',
                        displayName: 'Ruimtesoorten ERP',
                        icon: 'CollapseContent',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <RuimtesoortErpList/>
                    },
                    {
                        path: 'InrichtenTaken',
                        displayName: 'Inrichten niet-geïnspecteerde taken',
                        icon: 'TaskGroup',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <TenantTaakList/>
                    },
                    {
                        path: 'DefectlocatiesRuimtesoorten',
                        displayName: 'Defectlocaties || Ruimtesoorten',
                        icon: 'AddLink',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <TenantRuimteSoortList/>
                    },
                    {
                        path: 'Kostensoorten',
                        displayName: 'Kostensoorten',
                        icon: 'Money',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <KostensoortList/>
                    },
                    {
                        path: 'KostensoortGarantie',
                        displayName: 'Kostensoort garantie',
                        icon: 'QuestionnaireMirrored',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <GarantieList/>
                    },
                    {
                        path: 'DefectsoorzakenKostensoorten',
                        displayName: 'Defectoorzaken || Kostensoorten',
                        icon: 'Calculator',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <DefectoorzaakKostensoortList/>
                    },
                    {
                        path: 'BetalerKostensoort',
                        displayName: 'Betaler || Kostensoort',
                        icon: 'ConnectContacts',
                        allowedRoles: ['Reparatieboom'],
                        showInNav: true,
                        component: <TenantKostensoortBetalerList/>
                    },
                    {
                        path: 'TenantWoningverbetering',
                        displayName: 'Woningverbeteringen',
                        icon: 'HomeVerify',
                        allowedRoles: ['Woningverbetering'],
                        showInNav: true,
                        component: <TenantWoningverbeteringList/>
                    },
                    {
                        path: 'Werkgebied_List',
                        displayName: 'Werkgebieden',
                        icon: 'AreaChart',
                        showInNav: true,
                        allowedRoles: ['Reparatieboom'],
                        component: <WerkgebiedList />
                    },
                ]
            },
            {
                displayName: 'Relaties',
                key: 'relatiegroup',
                RouteGroups: [
                    {
                        path: 'Rechtspersonen_List',
                        displayName: 'Rechtspersonen',
                        icon: 'AccountManagement',
                        showInNav: true,
                        allowedRoles: ['Relatiebeheer'],
                        component: <RechtspersoonList/>
                    },
                    {
                        displayName: 'Rechtspersonen_New',
                        path: 'Rechtspersonen_New',
                        showInNav: false,
                        allowedRoles: ['Relatiebeheer'],
                        component: <RechtspersoonNew/>
                    },
                    {
                        displayName: 'Rechtspersonen_Edit',
                        path: 'Rechtspersonen_Edit',
                        showInNav: false,
                        allowedRoles: ['Relatiebeheer'],
                        component: <RechtspersoonEdit/>
                    },
                    {
                        path: 'VvEs',
                        displayName: 'VvE\'s',
                        icon: 'AccountManagement',
                        showInNav: true,
                        allowedRoles: ['Relatiebeheer'],
                        component: <VveList/>
                    },
                    {
                        path: 'VveDetails',
                        displayName: 'VveDetails',
                        showInNav: false,
                        allowedRoles: ['Relatiebeheer'],
                        component: <VveDetails/>
                    },
                    {
                        path: 'Contactgegevens',
                        displayName: 'Contactgegevens',
                        icon: 'ContactList',
                        showInNav: true,
                        allowedRoles: ['Relatiebeheer'],
                        component: <ContactgegevenList/>
                    }
                ]
            },
            {
                displayName: 'Importeren & exporteren',
                key: 'Importerengroup',
                RouteGroups: [
                    {
                        path: 'Importeren',
                        displayName: 'Importeren',
                        icon: 'BulkUpload',
                        allowedRoles: ['Vastgoedbeheer', 'Reparatieboom', 'Relatiebeheer', 'Cartotheekbeheer', 'Financieel', 'VgtAdministrator', 'Administrator'],
                        showInNav: true,
                        component: <Importeren/>
                    },
                    {
                        path: 'Exporteren',
                        displayName: 'Exporteren',
                        icon: 'BulkUpload',
                        allowedRoles: ['Reparatieboom', 'Relatiebeheer', 'Cartotheekbeheer', 'Financieel', 'VgtAdministrator', 'Administrator'],
                        showInNav: true,
                        component: <Exporteren/>
                    },
                    {
                        path: 'ImportResults',
                        displayName: 'ImportResults',
                        allowedRoles: ['VgtAdministrator', 'Administrator'],
                        showInNav: false,
                        component: <ImportResults/>
                    },
                    {
                        path: 'ImportElementen',
                        displayName: 'ImportElementen',
                        allowedRoles: ['VgtAdministrator', 'Administrator'],
                        showInNav: false,
                        component: <ImportElementen/>
                    },
                    {
                        path: 'Verwerkingen',
                        displayName: 'Verwerkingen',
                        icon: 'Processing',
                        allowedRoles: ['Data.Reader', 'Vastgoedbeheer', 'Reparatieboom', 'Relatiebeheer', 'Cartotheekbeheer', 'Financieel', 'VgtAdministrator', 'Administrator'],
                        showInNav: true,
                        component: <Verwerkingen/>
                    },
                    {
                        path: 'VerwerkingStarten',
                        displayName: 'Verwerking starten',
                        icon: 'Play',
                        allowedRoles: ['Administrator', 'Vastgoedbeheer'],
                        showInNav: true,
                        component: <InvokeVerwerking/>
                    },
                    {
                        path: 'VerwerkingsRegels',
                        displayName: 'VerwerkingsRegels',
                        allowedRoles: ['Administrator', 'Data.Reader'],
                        showInNav: false,
                        component: <VerwerkingsRegels/>
                    }
                ]
            },
            {
                displayName: 'Onderhoudscontracten',
                key: 'contractGroup',
                RouteGroups: [
                    {
                        path: 'Onderhoudscontracten',
                        displayName: 'Onderhoudscontracten',
                        icon: 'LinkedDatabase',
                        allowedRoles: ['Onderhoudscontract'],
                        showInNav: true,
                        component: <OnderhoudsContractGrid/>
                    },
                    {
                        path: 'OnderhoudsContractEdit',
                        displayName: 'OnderhoudsContractEdit',
                        allowedRoles: ['Onderhoudscontract'],
                        showInNav: false,
                        component: <ContractEdit/>
                    }
                ]
            },
            {
                displayName: 'Financieel',
                key: 'financieelGroup',
                RouteGroups: [
                    {
                        path: 'Dimensies_List',
                        allowedRoles: ['Administrator'],
                        displayName: 'Dimensies',
                        icon: 'ViewList',
                        showInNav: true,
                        component: <DimensiesList/>
                    },
                    {
                        path: 'Dimensies_New',
                        displayName: 'Dimensies_New',
                        allowedRoles: ['Administrator'],
                        showInNav: false,
                        component: <DimensiesNew/>
                    },
                    {
                        path: 'Dimensies_Edit',
                        displayName: 'Dimensies_Edit',
                        allowedRoles: ['Administrator'],
                        showInNav: false,
                        component: <DimensiesEdit/>
                    },
                    {
                        path: 'Dimensiewaarden_List',
                        displayName: 'Dimensiewaarden_List',
                        allowedRoles: ['Administrator'],
                        showInNav: false,
                        component: <DimensiewaardenList/>
                    },
                    {
                        path: 'Dimensiewaarden_New',
                        displayName: 'Dimensiewaarden_New',
                        allowedRoles: ['Administrator'],
                        showInNav: false,
                        component: <DimensiewaardenNew/>
                    },
                    {
                        path: 'Dimensiewaarden_Edit',
                        displayName: 'Dimensiewaarden_Edit',
                        allowedRoles: ['Administrator'],
                        showInNav: false,
                        component: <DimensiewaardenEdit/>
                    }
                ]
            },
            {
                displayName: 'Inspectie',
                key: 'inspectieGroup',
                RouteGroups: [
                    {
                        path: 'Categorieen',
                        displayName: 'Categorieën',
                        icon: 'LinkedDatabase',
                        allowedRoles: ['Inspectie'],
                        showInNav: true,
                        component: <Categorieen/>
                    },
                    {
                        path: 'Opnamedetail',
                        displayName: 'Opnamedetail',
                        icon: 'LinkedDatabase',
                        allowedRoles: ['Inspectie'],
                        showInNav: true,
                        component: <OpnamedetailCartotheek/>
                    },
                    {
                        path: 'Inspectie',
                        displayName: 'Inrichten inspectietaken',
                        icon: 'Taskboard',
                        allowedRoles: ['Inspectie'],
                        showInNav: true,
                        component: <TenantInspectietakenList/>
                    },
                    {
                        path: 'InspectieSjablonen_List',
                        displayName: 'Inspectiesjablonen',
                        icon: 'FabricPublicFolder',
                        allowedRoles: ['Inspectie'],
                        showInNav: true,
                        component: <InspectieSjabloonList />
                    },
                    {
                        displayName: 'InspectieSjablonen_New',
                        path: 'InspectieSjablonen_New',
                        showInNav: false,
                        allowedRoles: ['Inspectie'],
                        component: <InspectieSjabloonNew />
                    },
                    {
                        displayName: 'InspectieSjablonen_Edit',
                        path: 'InspectieSjablonen_Edit',
                        showInNav: false,
                        allowedRoles: ['Inspectie'],
                        component: <InspectieSjabloonEdit />
                    },
                ]
            },
            {
                displayName: 'Systeem',
                key: 'System',
                RouteGroups: [
                    {
                        path: 'SystemSettings',
                        displayName: 'Instellingen',
                        allowedRoles: ['Administrator'],
                        icon: 'Settings',
                        showInNav: true,
                        component: <SystemSettingsView/>
                    }
                ]
            },
        ]
    },
    {
        displayName: 'Beheer',
        key: 'Beheer',
        TopLevelGroup: [
            {
                displayName: 'BeheerSelector',
                key: 'BeheerSelectorGroup',
                RouteGroups: [
                    {
                        path: 'BeheerSelector',
                        displayName: 'BeheerSelector',
                        allowedRoles: ['VgtAdministrator', 'Administrator'],
                        showInNav: false,
                        component: <BeheerSelector/>
                    }

                ]
            }
        ]
    },
    {
        displayName: 'VGE',
        key: 'VGE',
        TopLevelGroup: [
            {
                displayName: 'VgeGroup',
                key: 'VgeGroup',
                RouteGroups: [
                    {
                        path: 'VgeEdit',
                        displayName: 'VgeEdit',
                        allowedRoles: ['Data.Reader', 'Vastgoedbeheer'],
                        showInNav: false,
                        component: <VgeEdit/>
                    },
                    {
                        path: 'VgeFromVhe',
                        displayName: 'VgeFromVhe',
                        allowedRoles: ['Data.Reader', 'Vastgoedbeheer', 'Verhuur'],
                        showInNav: false,
                        component: <VgeFromVhe/>
                    },
                    {
                        path: 'VgeSearchResultsLocation',
                        displayName: 'VgeSearchResultsLocation',
                        allowedRoles: ['Data.Reader', 'Vastgoedbeheer'],
                        showInNav: false,
                        component: <SearchResults/>
                    },
                    {
                        path: 'VgeSearchQueryTypeResults',
                        displayName: 'VgeSearchQueryTypeResults',
                        allowedRoles: ['Data.Reader', 'Vastgoedbeheer'],
                        showInNav: false,
                        component: <SearchResults/>
                    },
                    {
                        path: 'VgeSearchQueryResults',
                        displayName: 'VgeSearchQueryResults',
                        allowedRoles: ['Data.Reader', 'Vastgoedbeheer'],
                        showInNav: false,
                        component: <SearchResults/>
                    }
                ]
            }
        ]
    }
]

export { routes }