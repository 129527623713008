import {
  IStackTokens,
  mergeStyleSets,
  Pivot,
  PivotItem,
  Selection,
  SelectionMode,
  Stack,
  StackItem,
  Text
} from '@fluentui/react'
import { fetchRechtspersonen } from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EditMode } from './editPanelGebouwHoeveelheid'
import GebouwdeelAdressen from './gebouwdeelAdressen'
import GebouwdeelPercelen from './gebouwdeelPercelen'
import { IGebouwElementHoeveelheid } from './gebouwenSlice'
import HoeveelhedenList from './gebouwHoeveelheidList'
import {
  GebouwDeelContactCategorieList
} from '../gebouwdelen/gebouwDeelContactCategorie/GebouwDeelContactCategorieList'
import Oppervlakte from '../Oppervlakte/Oppervlakte'
import { CartotheekWizardPanel } from '../../cartotheek/CartotheekPanel'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import { GebouwHoeveelheidDisplayDto, VgeDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { GebouwDeelEditPanel } from './gebouwDeelEditPanel'
import { useBoolean } from '@fluentui/react-hooks'
import InputCard from '../../../../components/InputCard'
import locatie from '../../../vge/icons/Vastgoedtabel_Icons_BAG-locatie.png'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import GoogleMapReact from 'google-map-react'
import { DocumentDoelType } from '../../documents/DocumentList'
import { HuurcontractbepalingenTooltip } from '../../huurcontractbepaling/HuurcontractbepalingShow'
import { GebouwDeelBestemmingPanel } from './GebouwDeelBestemmingPanel'

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    margin: '10px'
  },
  filter: {
    backgroundColor: 'white',
    paddingBottom: 20,
    maxWidth: 300
  },
  header: {
    margin: 0,
    backgroundColor: 'white'
  },
  row: {
    display: 'inline-block'
  }
})

type IGebouwElementHoeveelheidType = z.infer<typeof GebouwHoeveelheidDisplayDto>;
type VgeDTO = z.infer<typeof VgeDto>;

const stackTokens: IStackTokens = { padding: '0', childrenGap: '20 20' }

const Gebouwdeel: React.FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [error, setError] = useState<string>()
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [selectedPivot, setSelectedPivot] = useState<string>('Gebouwdeel')

  const { data: gebouwDeel, invalidate: refetchGebouwdeel } = zodiosHooks.useGetGebouwdelenId({
    params: {
      id: parseInt(id!)
    }
  }, { enabled: id !== undefined })

  const [inBestemmingEditMode, { setTrue: openBestemmingEditPanel, setFalse: dismissBestemmingEditPanel }] = useBoolean(false)

  const { data: selectedGebouwen } = zodiosHooks.useGetGebouwendetailsId({
    params: {
      id: gebouwDeel?.gebouwId!
    }
  }, { enabled: gebouwDeel?.gebouwId !== undefined })

  const { data: rechtspersonenOptions } = zodiosHooks.useGetRechtspersonendropdownoptions()

  const {
    data: hoofdDocumentUrl,
    invalidate: refetchHoofdDocumentUrl
  } = zodiosHooks.useGetApiDocumentshoofdDocumentForDoelTypeDoelTypedoelIdDoelId({
    params: {
      doelType: DocumentDoelType.gebouwdeel,
      doelId: parseInt(id!)
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message),
    enabled: id !== undefined
  }
  )

  const serviceAdres = () => {
    const itemsArray = [] as VgeDTO[]
    if (selectedGebouwen?.vgesMetAdres) itemsArray.push(...selectedGebouwen.vgesMetAdres)
    if (selectedGebouwen?.gebouwDelen) selectedGebouwen?.gebouwDelen.forEach(value => itemsArray.push(...value.vgesMetAdres!))
    const selectedServiceAdres = itemsArray.find(item => item.id === gebouwDeel?.serviceadresVge)
    if (!selectedServiceAdres) return ''
    return `${selectedServiceAdres?.wpl}, ${selectedServiceAdres?.pc}, ${selectedServiceAdres?.str} ${selectedServiceAdres?.nr}${selectedServiceAdres?.ltr ?? ''}${selectedServiceAdres?.nrT ?? ''}`
  }

  const selectedGebouwdeel = selectedGebouwen?.gebouwDelen?.filter(v => v.id === parseInt(id!))

  const navigate = useNavigate()

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Wijzigen', onClick: () => openEditPanel(), icon: 'Edit' },
    { text: 'Wijzigen Bestemming', onClick: () => openBestemmingEditPanel(), icon: 'Edit' }
  ], [])

  const [hoeveelheden, setHoeveelheden] = useState([] as IGebouwElementHoeveelheidType[])

  const [metAdresSelection] = React.useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      items: selectedGebouwdeel?.[0].vgesMetAdres!,
      onSelectionChanged: () => metAdresSelection.count,
      getKey: item => item.id
    })
  })

  const [zonderAdresSelection] = React.useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      items: selectedGebouwdeel?.[0].vgesZonderAdres!,
      onSelectionChanged: () => zonderAdresSelection.count,
      getKey: item => item.id
    })
  })

  const fillHoeveelheden = () => {
    const itemArray = [] as IGebouwElementHoeveelheidType[]
    if (selectedGebouwen?.hoeveelheden)
      itemArray.push(...selectedGebouwen.hoeveelheden.filter(e => e.gebouwDeel === selectedGebouwdeel?.[0].code))
    setHoeveelheden(itemArray)
  }

  useEffect(() => {
    if (rechtspersonenOptions?.length === 0) {
      dispatch(fetchRechtspersonen({ filter: '' }))
    }
  }, [rechtspersonenOptions])

  useEffect(() => {
    metAdresSelection.setAllSelected(false)
    if (selectedGebouwdeel?.[0]?.vgesZonderAdres) selectedGebouwdeel?.[0]?.vgesZonderAdres.forEach(v => metAdresSelection.selectToKey(v.id))
    fillHoeveelheden()
  }, [selectedGebouwdeel?.[0]])

  const PhotoElement = () => {
    if (!hoofdDocumentUrl)
      return <div style={{ height: '270px', width: '397px', padding: '10px', paddingLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
    if (hoofdDocumentUrl?.hoofdDocumentUrl) {
      return (
        <img
          style={{
            marginTop: '10px',
            height: '270px',
            width: '397px',
            boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px',
            borderRadius: '8px'
          }}
          src={hoofdDocumentUrl?.hoofdDocumentUrl}
          alt='Hoofdfoto'
        />
      )
    } else {
      return (
        <div style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text>Er is geen hoofdfoto ingesteld om weer te geven</Text>
        </div>
      )
    }
  }

  const MarkerComponent = ({ text }: any) => <div className="pin">{text}</div>

  const calculateCenter = () => {
    if (gebouwDeel) {
      if (gebouwDeel?.coordinates?.length! > 0) {
        return {
          lat: gebouwDeel?.coordinates?.[0].lat,
          lng: gebouwDeel?.coordinates?.[0].long
        } as GoogleMapReact.Coords
      }

      return undefined
    }
  }

  const calculateZoom = () => {
    if (!gebouwDeel) return 0
    return gebouwDeel?.coordinates?.length! > 0 ? 18 : 6
  }

  const handleLinkClick = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
    const clickedPivotKey = item?.props.itemKey
    if (clickedPivotKey === 'Gebouwdeel' && selectedPivot !== 'Gebouwdeel') {
      refetchHoofdDocumentUrl()
    }
    setSelectedPivot(clickedPivotKey ?? '')
  }

  return (
    <MainTemplate
      title={gebouwDeel?.naam!}
      subTitle="Gebouwdeel"
      commandItems={commandItems}
    >
      <GebouwDeelEditPanel
        isOpen={inEditMode}
        dismissPanel={dismissEditPanel}
        fetchData={refetchGebouwdeel}
        selectedGebouwdeel={gebouwDeel}
        gebouwDelen={selectedGebouwdeel?.[0]}
      />
      <GebouwDeelBestemmingPanel dismissPanel={dismissBestemmingEditPanel} isOpen={inBestemmingEditMode}
        gebouwDeelDetails={gebouwDeel} invalidate={refetchGebouwdeel} />
      <div className={classNames.wrapper}>
        <ErrorMessageBar error={error} />
        <Pivot onLinkClick={handleLinkClick}>
          <PivotItem itemKey="Gebouwdeel" headerText="Gebouwdeel">
            <Stack
              tokens={stackTokens}
              style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px', height: '72vh' }}
              horizontal
            >
              <StackItem grow={1}>
                <Stack>
                  <StackItem>
                    <InputCard title="Gebouwdeel" icon={locatie} width="700">
                      <ReadOnlyField title={'Code'} value={gebouwDeel?.code} labelWidth={171} />
                      <ReadOnlyField title={'Naam'} value={gebouwDeel?.naam} labelWidth={171} />
                      <ReadOnlyField title={'Gebouw'} value={selectedGebouwen?.naam} labelWidth={171} />
                      <ReadOnlyField title={'Bestemming'} value={gebouwDeel?.bestemmingNaam} labelWidth={171} />
                      <ReadOnlyField title={'Serviceadres'} value={serviceAdres()}
                        autoAdjustHeight resizable multiline labelWidth={171} />
                      <HuurcontractbepalingenTooltip huurcontractbepalingen={gebouwDeel?.huurcontractbepalingen}
                        labelWidth={171} />
                      <ReadOnlyField title={'Advertentietekst'} value={gebouwDeel?.advertentietekst} labelWidth={171} />
                    </InputCard>
                  </StackItem>
                  <StackItem>
                    <ErrorMessageBar error={error} />
                    <PhotoElement />
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem grow={5}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCvaLeRfzfAtc7LJxbk5HhtcvB6WrKtX40', language: 'nl-NL' }}
                  defaultCenter={{ lat: 52.0907374, lng: 5.1214201 }}
                  defaultZoom={6}
                  options={{ mapTypeControl: true, mapTypeId: 'roadmap' }}
                  center={calculateCenter()}
                  zoom={calculateZoom()}
                >
                  {gebouwDeel?.coordinates?.map(item => (
                    <MarkerComponent key={item.id} lat={item.lat ?? 51.72835975} lng={item.long ?? 5.01544399}
                      text={item.nr} />
                  ))}
                </GoogleMapReact>
              </StackItem>
            </Stack>
          </PivotItem>
          <PivotItem headerText="Adresseerbaar" itemCount={selectedGebouwdeel?.[0]?.vgesMetAdres?.length ?? 0}>
            <GebouwdeelAdressen gebouwdeelId={parseInt(id!)} gebouwId={gebouwDeel?.gebouwId!} />
          </PivotItem>
          <PivotItem headerText="Overig gebouwd" itemCount={selectedGebouwdeel?.[0]?.vgesZonderAdres?.length ?? 0}>
            <GebouwdeelPercelen gebouwdeelId={parseInt(id!)} gebouwId={gebouwDeel?.gebouwId!} isTerrein={false} />
          </PivotItem>
          <PivotItem headerText="Terreinen"
            itemCount={selectedGebouwdeel?.[0]?.vgesZonderAdres?.filter(x => x.srt === 'TER').length ?? 0}>
            <GebouwdeelPercelen gebouwdeelId={parseInt(id!)} gebouwId={gebouwDeel?.gebouwId!} isTerrein={true} />
          </PivotItem>
          <PivotItem headerText="Cartotheek" itemKey="cartotheek">
            <CartotheekWizardPanel gebouwdeelId={gebouwDeel?.gebouwDeelId} />
          </PivotItem>
          <PivotItem headerText="Bouwkundige elementen" itemCount={0}></PivotItem>
          <PivotItem headerText="NEN2580">
            <Oppervlakte gebouwdeelId={gebouwDeel?.gebouwDeelId} />
          </PivotItem>
          <PivotItem headerText="Conditiemeting" itemCount={hoeveelheden.length}>
            <HoeveelhedenList hoeveelheden={hoeveelheden as IGebouwElementHoeveelheid[]}
              editMode={EditMode.gebouwdeel} />
          </PivotItem>
          <PivotItem headerText="Contactgegevens">
            <GebouwDeelContactCategorieList gebouwDeelId={gebouwDeel?.gebouwDeelId!} />
          </PivotItem>
        </Pivot>
      </div>
    </MainTemplate>
  )
}

export default Gebouwdeel
