import React, {useMemo, useState} from 'react'
import {
  CommandBar,
  IColumn,
  ICommandBarItemProps, IObjectWithKey,
  Link,
  Selection,
  SelectionMode  
} from '@fluentui/react'
import commandBarStyles from 'styles/commandBarStyles'
import {IVge} from 'features/vge/vgeSlice'
import {getPropertyName} from 'lib/interfaceUtils'
import {useBoolean} from '@fluentui/react-hooks'
import SelectAdressenPanel from './selectAdressenPanel'
import {createBooleanColumn, createColumn, createCustomRenderedColumn} from 'lib/gridHelper'
import {createCommandButton} from 'lib/commandBarHelper'
import {PageIdDefinition, PAGES} from 'pages'
import {useNavigate} from 'react-router-dom'
import {useWarningDialog} from 'components/WarningDialog'
import {zodiosHooks} from '../../../../api/ApiClient'
import {getTitleAndMessage} from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import {VgeHistorischPanel} from "../../../vge/VgeHistorischPanel";
import FluentTable from "../../../../components/FluentTable";


interface IGebouwAdressenProps {
  gebouwId: number
  updateSelectedGebouw: any
}

const GebouwAdressen: React.FC<IGebouwAdressenProps> = ({gebouwId, updateSelectedGebouw}) => {
  const [isPanelOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false)
  const navigate = useNavigate()
  const [linkContracten, setLinkContracten] = useState(false)
  const [error, setError] = useState<string>()
  const [WarningDialog, setDialogSubtext, showDialog] = useWarningDialog()
  const [inHistorischMode, { setTrue: openHistorischPanel, setFalse: dismissHistorischPanel }] = useBoolean(false)
  const [historischButton,{setTrue: showHistorischButton, setFalse: hideHistorischButton }] = useBoolean(false)
  const [selectedIds, setSelectedIds] = useState([] as number[])

  const onModified = () => {
    dismissHistorischPanel()
  }  

  const {data: gebouw, invalidate: fetchGebouwen} = zodiosHooks.useGetGebouwendetailsId({
    params: {
      id: gebouwId
    }
  }, {enabled: gebouwId !== 0})

  const {mutate: addVgesToGebouw} = zodiosHooks.usePostGebouwenVge({}, {
      onSuccess: () => fetchGebouwen(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {mutate: deleteVgesFromGebouw} = zodiosHooks.useDeleteGebouwenVge({}, {
    onSuccess: () => fetchGebouwen(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const [selection] = useState(() => new Selection<IObjectWithKey>({
    selectionMode: SelectionMode.multiple,
    onSelectionChanged: () => {      
      return selection.count
    },
    getKey: item => (item as IVge).id
  }))

  const onSelectionChanged = (ids: string[]) => {
    const selectedIds = ids.map(id => parseInt(id))
    selectedIds.length > 0 ? showHistorischButton() : hideHistorischButton()
    setSelectedIds(selectedIds)
  }
  
  const onLinkContractenChanged = (link: boolean) => {
    setLinkContracten(link)
  }

  const CheckForServiceadres = (vge: IVge, items: any) => {
    if (gebouw?.serviceadresVge && items.find((v: IVge) => v.id === gebouw?.serviceadresVge)) {
      setDialogSubtext('Vastgoedeenheid kan niet worden ontkoppeld aangezien deze wordt gebruikt als serviceadres')
      showDialog(true)
    } else {
      deleteVgesFromGebouw({gebouwId: gebouwId, vgeIds: [vge.id]})
    }
  }

  const AddToSelection = () => {
    updateSelectedGebouw()
    openPanel()
  }

  const RemoveFromSelection = () => {
    if (selection.count > 0) {
      const items = selection.getSelection().map((item) => { return item as IVge })
      updateSelectedGebouw()

      items.forEach(vge => {
        CheckForServiceadres(vge, items)
      })
    }
  }

  const onConnectVges = (items: number[]) => {
    addVgesToGebouw({gebouwId: gebouwId, vgeIds: items, linkVgeContracten: linkContracten})
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      createCommandButton('Koppelen', 'PlugConnected', AddToSelection),
      createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection),
      createCommandButton('Historisch maken', 'Edit', openHistorischPanel, historischButton)],
    [AddToSelection, RemoveFromSelection, historischButton, selectedIds]
  )

  const handleItemInvoked = ({id}: IVge) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id.toString()))
  }

  const columns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'XL'),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<IVge>('str'),
      (item: IVge) => (
        <Link key={item.str} onClick={() => handleItemInvoked(item)}>
          {item.str}
        </Link>
      ),
      'XL',
      false
    ),
    createColumn('Huisnr.', getPropertyName<IVge>('nr')),
    createColumn('Huislet.', getPropertyName<IVge>('ltr')),
    createColumn('Toev.', getPropertyName<IVge>('nrT')),
    createColumn('Postcode', getPropertyName<IVge>('pc')),
    createColumn('Gebouwdeel', getPropertyName<IVge>('gdlCd')),
    createCustomRenderedColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125, false),
    createColumn('Beheerder', getPropertyName<IVge>('bn'), 'XL'),
    createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'S')
  ]

  return (
    <>
      <VgeHistorischPanel onModified={onModified} dismissPanel={dismissHistorischPanel} isOpen={inHistorischMode}
                          vgeIds={selectedIds}/>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles}/>
      {WarningDialog}
      <ErrorMessageBar error={error}/>
      <SelectAdressenPanel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        onConnectVges={onConnectVges}
        linkVgeContracten={linkContracten}
        onLinkVgeContractenChanged={onLinkContractenChanged}
      />
      <div style={{height: '70vh', position: 'relative'}}>
        <FluentTable 
          columns={columns}
          items={gebouw?.vgesMetAdres ?? []}
          onGetKey={(item) => item.id}
          selection={selection}
          onSelectionChanged={onSelectionChanged}
          onItemInvoked={() => null}
        ></FluentTable>        
      </div>
    </>
  )
}

export default GebouwAdressen
