import {
  CommandBar,
  DetailsList,
  IColumn,
  ICommandBarItemProps,
  IDetailsColumnStyles,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode
} from '@fluentui/react'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useMemo, useState } from 'react'
import commandBarStyles from 'styles/commandBarStyles'
import { useNavigate } from 'react-router-dom'
import { GebouwDeelDto, GebouwdeelListDto, zodiosHooks } from '../../../../api/ApiClient'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { availableUrls } from '../../../../routing/AvailableUrls'
import { z } from 'zod'
import { GebouwDeelEditPanel } from './gebouwDeelEditPanel'
import { useBoolean } from '@fluentui/react-hooks'

type GebouwDeelDtoType = z.infer<typeof GebouwDeelDto>;
type GebouwdeelListDtoType = z.infer<typeof GebouwdeelListDto>;

interface IGebouwAdressenProps {
  gebouwId?: number
  gebouwDeelen: GebouwDeelDtoType[] | GebouwdeelListDtoType[]
  fetchGebouwdeelItems: () => void;
}

const cellStyles: Partial<IDetailsColumnStyles> = {
  root: {
    fontSize: '14px',
    fontWeight: 600
  }
}

const GebouwDelen: React.FC<IGebouwAdressenProps> = props => {
  const navigate = useNavigate()
  const [error, setError] = useState<string>()
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)

  const [selection] = React.useState<Selection<GebouwDeelDtoType>>(() => {
    return new Selection<GebouwDeelDtoType>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.id
    })
  })

  const handleItemInvoked = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  const AddToSelection = () => {
    openEditPanel()
  }

  const { mutate: deleteGebouwdeel } = zodiosHooks.useDeleteGebouwdelen({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const RemoveFromSelection = () => {
    const items = selection?.getSelection()
    deleteGebouwdeel({ itemsToDelete: items.map(v => v.id) })
  }

  const createColumn = (title: string, field: string) => {
    return {
      name: title,
      fieldName: field,
      key: field,
      minWidth: 50,
      maxWidth: 230,
      isResizable: true,
      styles: { cellStyles }
    } as IColumn
  }
  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'add',
        iconOnly: true,
        iconProps: { iconName: 'Add', className: 'icon' },
        split: false,
        ariaLabel: 'Toevoegen',
        onClick: AddToSelection
      },
      {
        key: 'delete',
        iconOnly: true,
        iconProps: { iconName: 'Delete', className: 'icon' },
        split: false,
        ariaLabel: 'Verwijderen',
        disabled: false,
        onClick: RemoveFromSelection
      }
    ],
    [selection?.count ?? 0]
  )
  const columns = [
    createCustomRenderedColumn(
      'Code',
      getPropertyName<GebouwDeelDtoType>('code'),
      (item: GebouwDeelDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      ),
      'S',
      false
    ),
    createColumn('Naam', getPropertyName<GebouwDeelDtoType>('naam')),
    createColumn('Gebouw Naam', getPropertyName<GebouwDeelDtoType>('gebouwNaam'))
  ]

  const extraColumns = [
    createCustomRenderedColumn('Aantal adresseerbaar', 'adresseerbaar vges', (item: GebouwDeelDtoType) =>
      <div>{item.vgesMetAdres?.length ?? 0}</div>, 'L', false),
    createCustomRenderedColumn(
      'Aantal overig gebouwd',
      'overig gebouwd vges',
      (item: GebouwDeelDtoType) => <div>{item.vgesZonderAdres?.filter(x => x.srt !== 'TER').length ?? 0}</div>,
      'L',
      false
    ),
    createCustomRenderedColumn(
      'Aantal terreinen',
      'terreinen vges',
      (item: GebouwDeelDtoType) => <div>{item.vgesZonderAdres?.filter(x => x.srt === 'TER').length ?? 0}</div>,
      'L',
      false
    )
  ]

  const isGebouwDeelDtoType = (gebouw: GebouwDeelDtoType | GebouwdeelListDtoType): gebouw is GebouwDeelDtoType => {
    return 'vgesZonderAdres' in gebouw
  }

  if (props.gebouwDeelen?.length > 0 && isGebouwDeelDtoType(props.gebouwDeelen[0])) {
    if (props.gebouwDeelen[0].vgesZonderAdres) {
      columns.push(...extraColumns)
    }
  }

  return (
    <>
      <GebouwDeelEditPanel isOpen={inEditMode} dismissPanel={dismissEditPanel}
                           fetchData={props.fetchGebouwdeelItems} gebouwId={props.gebouwId} />
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      <ErrorMessageBar error={error} />
      <div style={{ height: '78.7vh', position: 'relative' }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            columns={columns}
            selection={selection as Selection}
            styles={{ root: { fontSize: '14px' } }}
            items={props.gebouwDeelen ?? []}
          />
        </ScrollablePane>
      </div>
    </>
  )
}

export default GebouwDelen
